import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ThemeProvider from "@material-ui/styles/ThemeProvider"
import theme from "../theme"
import {
  Grid,
  Typography,
  makeStyles,
  createStyles,
  Theme,
} from "@material-ui/core"
import Image from "../components/image"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
  })
)

export default function AboutUs() {
  const classes = useStyles()

  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <Layout>
          <SEO title="About Us" />
          <div style={{ width: "100%" }}>
            <Image alt="Family walking" filename="services-banner-1a.jpeg" />
          </div>
          <br />
          <br />
          <Typography variant="h5">
            Employ the services of an expert lawyer in Winnipeg
          </Typography>
          <Typography variant="body1">
            Whether it's finding a fair price on your house, drafting up your
            last will, business incorporation, or any other issue requiring an
            expert lawyer in Winnipeg, contact Punzalan Law. Book your initial
            consultation online and contact us if you have any other questions.
          </Typography>
          <br />
          <div className={classes.root}>
            <Grid container spacing={8}>
              <Grid item xs={6}>
                <Typography variant="h5">Commercial Law</Typography>
                <Typography variant="body1">
                  We practice in many areas of business law, including:
                  <ul>
                    <li>Commercial Leasing</li>
                    <li>Commercial Purchases and Sales</li>
                    <li>Confidentiality and Non-Competition Agreements</li>
                    <li>Consulting Agreements</li>
                  </ul>
                  We have worked with many different groups, including:
                  <ul>
                    <li>Developers</li>
                    <li>Construction companies and builders</li>
                    <li>
                      Professionals acquiring or selling a business practice
                    </li>
                    <li>
                      Landlords and tenants of shopping centres, office
                      buildings, and retail premises
                    </li>
                    <li>Institutional and private lenders</li>
                  </ul>
                </Typography>
                <Typography variant="h5">Residential Real Estate</Typography>
                <Typography variant="body1">
                  When you need a real estate lawyer in Manitoba to assist you
                  with buying or selling property, contact Punzalan Law. We have
                  experience in many areas of real estate law and various
                  related areas and are dedicated to helping you find the best
                  possible solution.
                </Typography>
                <br />
                <Typography variant="body1">
                  We work to offer advice and practical solutions in a variety
                  of real estate areas including:
                  <ul>
                    <li>Preparing and reviewing purchase contracts</li>
                    <li>Reviewing condominium documents</li>
                    <li>Conveyancing</li>
                    <li>Mortages</li>
                    <li>Closing adjustments</li>
                  </ul>
                </Typography>

                <Typography variant="h5">
                  Last Wills and Powers of Attorney
                </Typography>
                <Typography variant="body1">
                  Our areas of expertise include the following:
                  <ul>
                    <li>Preparation of Last Wills</li>
                    <li>
                      Preparation of Limited or Enduring Powers of Attorney
                    </li>
                  </ul>
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography variant="h5">Corporate Law </Typography>
                <Typography variant="body1">
                  We provide companies and other organizations with the timely
                  delivery of personalized and cost-effective filing and
                  registration services, due diligence searches, and compliance
                  with routine corporate and commercial tasks.{" "}
                  <ul>
                    <li>
                      <b>Incorporations and Registrations</b> include reserving
                      the corporation name, preparing and filing the necessary
                      documents, obtaining a corporate seal and opening the
                      minute book, preparing registers and a reporting booklet
                      to the client, and acting as registered and records
                      office.
                    </li>
                    <li>
                      <b>Ongoing Annual Filings</b> includes annual maintenance
                      and record-keeping services, including maintaining
                      corporate minute books and records, forwarding and filing
                      annual reports and reminding companies of their annual
                      meeting obligations, and preparing resolutions of
                      directors and members.
                    </li>
                  </ul>
                </Typography>

                <Typography variant="h5">
                  Construction and Development
                </Typography>
                <Typography variant="body1">
                  We can offer a variety of construction-related services
                  involving commercial, industrial, and residential projects,
                  which includes:
                  <ul>
                    <li>
                      Negotiation, drafting, and revision of various types of
                      construction contracts, professional services, and
                      management contracts
                    </li>
                    <li>Builders' liens</li>
                    <li>
                      Negotiation, drafting, and revising of financing and
                      security documentation
                    </li>
                  </ul>
                </Typography>

                <Typography variant="h5">Domestic Law </Typography>
                <Typography variant="body1">
                  In the area of domestic law, we are able to help with a
                  variety of issues, including:
                  <ul>
                    <li>Prenuptial and Cohabitation Agreements</li>
                    <li>Child custody and Spousal Agreements </li>
                    <li>Separation Agreements</li>
                    <li>Divorce and property division</li>
                  </ul>
                </Typography>
              </Grid>
            </Grid>
          </div>
        </Layout>
      </ThemeProvider>
    </React.Fragment>
  )
}
